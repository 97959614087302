
.gift-content {
  &, & * {
      box-sizing: border-box;
  }
  .el-breadcrumb-c {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      padding-left: 20px;
      background-color: #edf2f9;
      border-radius: 5px;
      &:deep(.el-breadcrumb) {
           .el-breadcrumb__inner {
               color: #5e6e82;
           }
        }

  }
  .gift-form {
      width: 700px;
      .upload-img-area, .upload-img-cover {
          width: 150px;
          height: 150px;
          border-radius: 20px;
          border: 1px dashed #cdd0d6;
          background-color: #fafafa;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;
      }
      .upload-img-cover {
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
          .gift-img-preview {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              span {
                  display: none;
              }
              &:hover {
                  background-color: rgba(0, 0, 0, .3);
                  & span {
                    display: inline-block;
                  }
              }
          }
      }
  }
}
